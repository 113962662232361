.ItemDetail {
    padding: 1em;
  }
  
  .ImageContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background-color: #ffffff;
    object-fit: cover;
  }
  
  .ImageContainer img {
    max-width: 400px;
    max-height: 800px;
    object-fit: cover;
    margin-bottom: 1em;
    border-radius: 10px;
  }
  
  .PhotoList {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: white;
  }
  
  .PhotoList li {
    cursor: pointer;
    margin-right: 0.5em;
  }
  
  .PhotoList li img {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
  
  .InfoContainer {
    padding: 1em;
    background-color: white;
  }
  
  .InfoContainer h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  @media (max-width: 767px) {
    .ItemDetail {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .ImageContainer {
      width: 100%;
    }
  
    .ImageContainer img {
      max-height: 500px;
    }
  }