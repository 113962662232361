.ContainerNavbar img {
  width: 63px;
  padding: 0;
  margin: 0;
}

.ContainerNavbar {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  position: fixed;
  width: 100%;
  z-index: 999;
  background: linear-gradient(
    45deg,
    rgb(65, 18, 16),
    rgb(233, 17, 17),
    rgb(194, 44, 44),
    rgb(87, 17, 17)
  );
  width: 100%;
  padding: 0;
}
.ContainerNavbarMobile {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  position: fixed;
  width: 100%;
  z-index: 999;
  background: linear-gradient(
    45deg,
    rgb(255, 255, 255),
    rgb(255, 255, 255),
    rgb(255, 255, 255),
    rgb(255, 255, 255)
  );
  width: 100%;
  padding: 0;
  justify-content: center;
  border-color: rgb(46, 46, 46);
  display: inline-block;

}
.nav-link {
  margin-left: 10px;
  font-family: "Montserrat";
  font-weight: 500 !important;
  color: white;
  text-align: center;
}

.nav-link:hover span {
  transition: 0.5s;
  color: rgb(0, 0, 0);
}

.NavLink {
  list-style: none;
  padding: 0;
  font-size: large;
}

.NavLink a {
  text-decoration: none;
  color: black;
}

.Cart {
  /* background-color: rgb(233, 17, 17); */
  padding: 0;
  font-family: "Montserrat";
  font-weight: 500 !important;
  color: white;
}

.me-auto a {
  text-decoration: none;
  color: black;
  font-size: large;
  padding: 21px;
}

.me-auto a:hover {
  background: black;
  color: rgb(247, 245, 245);
}
