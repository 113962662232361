.ItemsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

.ItemsContainer li {
  margin: 1em;
  padding: 1em;
  list-style: none;
  background-color: #f7f7f7;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 1);
  border-radius: 4px;
}

.ItemsContainer img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}
.item-list {
  flex-grow: 1;
  /* margin-left: 10px; */
  padding: 20px;
}