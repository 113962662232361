.galeria img {
  width: 100%;
}
.section {
  font-family: "Montserrat";
  font-weight: 800;
  color: white;
}
.section h1 {
  font-family: "Montserrat";
  font-weight: 900;
  color: white;
}

.bottom-text {
  position: absolute;
  bottom: 0;
  font-size: x-large;
  margin-bottom: 50px;
}

.bottom-text-title {
  position: absolute;
  bottom: 0;
  margin-bottom: 90px;
}

.bottom-text-hours {
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  font-weight: 500;
}

@media (max-width: 728px) {
  .bottom-text {
    position: absolute;

    text-align: center;
    font-size: small;
    bottom: 0;
  }
  .bottom-text-title {
    position: absolute;
    bottom: 0;
    margin-bottom: 100px;
    font-weight: 900;
  }
  .bottom-text-hours {
    position: absolute;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    bottom: 0;
    margin-bottom: 10px;
    font-size: small;
    font-weight: 500;
  }
}
