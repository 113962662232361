.Footer {
  background: linear-gradient(
    45deg,
    rgb(65, 18, 16),
    rgb(233, 17, 17),
    rgb(194, 44, 44),
    rgb(87, 17, 17)
  );    padding: 30px 0;
    font-family: "Montserrat";
    font-weight: 500 !important;
    color: white;
    text-align: center;
    position: relative;
    z-index: 1400;
  }
  
  .Footer img {
    max-height: 90px;
  }
  
  .footer-section {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  
  .footer-section h5 {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .footer-section p {
    margin: 0;
  }
  
  .footer-section svg {
    width: 26px;
    height: 26px;
    fill: currentColor;
    margin-right: 12px;
  }
  
  .Footer .text-center {
    margin-top: 20px;
  }
  
  .Footer .mt-4 {
    margin-top: 40px;
  }
  