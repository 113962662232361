.contact-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 10);
}
.bottom-textt-hours {
  font-weight: 500;
}

.rowClass {
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  display: grid;
}

.contact-card {
  width: 20rem;
}

.contact-container {
  min-height: 500; /* Ajusta la altura según tus necesidades */
}

@media screen and (max-width: 1400px) {
  .contact-container {
    /* Agrega tus estilos específicos para móvil aquí */
    /* Por ejemplo, puedes cambiar la altura o el padding */
    min-height: 50vh;
    display: block;
    padding-top: 30px;
  }
  .contact-card {
    width: 14rem;
  }
}
@media screen and (max-width: 1195px) {
  .contact-container {
    /* Agrega tus estilos específicos para móvil aquí */
    /* Por ejemplo, puedes cambiar la altura o el padding */
    min-height: 50vh;
    display: block;
    padding-left: 10px;
    padding-top: 30px;
  }
  .contact-card {
    width: 16rem;
  }
}

/* Estilos para dimensiones móviles */
@media screen and (max-width: 400px) {
  .contact-container {
    /* Agrega tus estilos específicos para móvil aquí */
    /* Por ejemplo, puedes cambiar la altura o el padding */
    min-height: 50vh;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .contact-card {
    width: 12rem;
  }
}
