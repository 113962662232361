.image-container {
    position: relative;
    overflow: hidden;
  }
  
  .image {
    transition: transform 0.3s;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .overlay-text {
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  
  .image-container:hover .image {
    transform: scale(1.1);
  }
  
  .image-container:hover .overlay {
    opacity: 1;
  }