@media (min-width: 728px){
    .img{
        display: flex;  
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }


}
.carousell img{
    justify-content: center;
     width: 250px;
    height: 130px;
    text-align: center;
}

.carousell{
    justify-content: center;
    width: 200px;
    background-color: rgb(0, 0, 0);
}