
Button{
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
}
.ProductName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

@media (min-width: 728px){
    li{
        display: flex;  
        flex-direction: column;
        align-items: center;
    }

}

.card1 {
    width: 200px;
}